

















































import Vue from "vue";
import { NewTenantsApiFunc, NewVacanciesApiFunc, setAccessToken } from "@/api";
import { AuthParameter } from "@/models";
import BaseInputForm from "@/components/BaseInputForm.vue";
import BaseSubmitButton from "@/components/BaseSubmitButton.vue";
import BaseErrorMessage from "@/components/BaseErrorMessage.vue";

export type DataType = {
  authParameter?: AuthParameter;
  passcode?: string;
  loginId?: string;
  password?: string;
};

export default Vue.extend({
  name: "LoginScreen",

  components: {
    BaseInputForm,
    BaseSubmitButton,
    BaseErrorMessage,
  },

  data(): DataType {
    return {
      authParameter: undefined,
      passcode: undefined,
      loginId: undefined,
      password: undefined,
    };
  },

  async mounted() {
    this.getAuthParameter();
    this.$auth.restore();

    const websiteCode = this.$auth.websiteCode;
    if (this.$auth.isAuthed() && websiteCode) {
      await this.getTenantWebsite();
      this.$router.push({
        name: "tenant-website-detail",
        params: {
          websiteCode: websiteCode,
        },
      });
    }
  },

  computed: {
    canSubmit(): boolean {
      if (!this.shouldShowPasscodeAuthField && !this.shouldShowUserAuthField) {
        return true;
      }
      if (this.shouldShowPasscodeAuthField && !this.passcode) {
        return false;
      }
      if (this.shouldShowUserAuthField && (!this.loginId || !this.password)) {
        return false;
      }
      return true;
    },

    shouldShowPasscodeAuthField(): boolean {
      return this.authParameter?.passcodeAuth === true;
    },

    shouldShowUserAuthField(): boolean {
      return this.authParameter?.userAuth === true;
    },

    containerClassText(): string[] {
      const classes = [];
      if (this.shouldShowPasscodeAuthField) {
        classes.push("passcode-auth");
      }
      if (this.shouldShowUserAuthField) {
        classes.push("user-auth");
      }
      return classes;
    },
  },

  methods: {
    async getAuthParameter(): Promise<void> {
      const websiteCode = this.$router.currentRoute.params.websiteCode;

      this.$setLoading(true);
      const { data, err } = await NewTenantsApiFunc().getAuthParameter(websiteCode);

      if (data === undefined) {
        this.$router.push({ name: "not-found" });
        return;
      }

      if (err != null) {
        this.$setLoading(false);
        this.$handleApiError(err, this.getAuthParameter);
        return;
      }

      this.authParameter = data;
      if (!this.shouldShowPasscodeAuthField && !this.shouldShowUserAuthField) {
        this.tryLogin();
        return;
      }
      this.$setLoading(false);
    },

    async tryLogin(): Promise<void> {
      if (!this.canSubmit) {
        return;
      }

      this.$setLoading(true);
      const websiteCode = this.$router.currentRoute.params.websiteCode;
      const err = await Vue.prototype.$auth.login(websiteCode, this.passcode, this.loginId, this.password);
      if (err) {
        this.$setLoading(false);
        this.$handleApiError(err, this.tryLogin);
        return;
      }
      this.$auth.setWebsiteCode(websiteCode);
      setAccessToken(this.$auth.accessToken);

      this.$setChecked(true);
      this.$setLoading(false);
      this.$afterSuccessRequest(() => {
        if (this.$auth.websiteCode) {
          this.getTenantWebsite();
        }
        this.$router.push(`/${websiteCode}`);
      }, 2);
    },

    async getTenantWebsite(): Promise<void> {
      const websiteCode = this.$auth.websiteCode as string;
      const res = await NewVacanciesApiFunc().getTenantWebsite(websiteCode);

      if (res.err != null) {
        this.$handleApiError(res.err, this.getTenantWebsite);
        return;
      }
    },

    afterLeave(): void {
      this.$setChecked(false);
    },
  },
});
